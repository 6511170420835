.component-filter-buttons {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
}
.component-filter-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  font-size: 14px;
  border-bottom: 1px solid #e3e3e3;
}
.component-filter-item-left {
  display: flex;
  flex-direction: column;
}
.component-filter-item-right {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
}
.component-filter-item-time {
  color: gray;
  font-size: 12px;
}
.component-filter-scrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 2px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.component-filter-scrollbar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #e3e3e3;
}
